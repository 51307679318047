<template>
    <b-sidebar
        id="edit-menu-sidebar"
        :visible="isEditMenuSidebarActive"
        backdrop
        bg-variant="white"
        no-header
        right
        shadow
        sidebar-class="sidebar-lg"
        @change="(val) => $emit('update:is-edit-menu-sidebar-active', val)"
    >
        <template #default="{ hide }">
            <!-- Header -->
            <div
                class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
            >
                <h5 class="mb-0">
                    <b-icon-cursor-fill class="mr-1" />
                    {{ menuItem.menu_id ? "编辑导航" : "添加导航" }}
                </h5>

                <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="hide"
                />
            </div>

            <!-- Form -->
            <b-form class="p-2">
                <!-- Title -->

                <b-form-group label="导航名称" label-for="title">
                    <b-form-input id="title" v-model="menuItem.title" trim />
                </b-form-group>

                <!-- Menu Id -->
                <b-form-group
                    v-if="menuItem.menu_id"
                    label="导航ID"
                    label-for="menu-id"
                >
                    <b-form-input
                        id="menu-id"
                        v-model="menuItem.menu_id"
                        autofocus
                        disabled
                        trim
                    />
                </b-form-group>

                <!-- Menu P_menu_id -->
                <b-form-group label="父级导航ID" label-for="p_menu_id">
                    <b-form-input id="p_menu_id" v-model="menuItem.p_menu_id" />
                </b-form-group>

                <!-- Menu type -->
                <b-form-group label="导航类型" label-for="menu_type">
                    <v-select
                        id="menu_type"
                        v-model="menuItem.type"
                        :clearable="false"
                        :disabled="!!menuItem.menu_id"
                        :options="
                            menuItem.menu_id
                                ? [
                                      { text: '目录', value: 0 },
                                      { text: '展示', value: 1 },
                                      { text: '管理', value: 2 },
                                      { text: '仪表板', value: 3 },
                                      { text: '外链', value: 4 },
                                  ]
                                : [
                                      { text: '目录', value: 0 },
                                      { text: '展示', value: 1 },
                                      { text: '仪表板', value: 3 },
                                      { text: '外链', value: 4 },
                                  ]
                        "
                        :reduce="(text) => text.value"
                        class="rounded"
                        label="text"
                    />
                </b-form-group>
                <!-- Menu type -->
                <b-form-group label="所属项目" label-for="project_id">
                    <v-select
                        id="project_id"
                        v-model="menuItem.project_id"
                        :clearable="false"
                        :options="[
                            { text: 'All', value: 0 },
                            {
                                text: '当前',
                                value: project_selected,
                            },
                        ]"
                        :reduce="(text) => text.value"
                        class="rounded"
                        disabled
                        label="text"
                    />
                </b-form-group>
                <!-- Icon -->
                <b-form-group label="导航图标" label-for="icon">
                    <b-input-group>
                        <b-form-input id="icon" v-model="menuItem.icon" trim />
                        <b-input-group-append is-text>
                            <feather-icon :icon="menuItem.icon" />
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>

                <b-form-group
                    v-if="menuItem.type === 4"
                    label="外链网址"
                    label-for="route_params"
                >
                    <b-input
                        id="route_params"
                        v-model="menuItem.route_params.src"
                        :placeholder="$t('Link Url')"
                    ></b-input>
                </b-form-group>
                <!-- Route_name -->
                <b-form-group
                    v-if="menuItem.type !== 3 && menuItem.type !== 0"
                    label="路由"
                    label-for="route_name"
                >
                    <b-input
                        v-if="[1, 4].includes(menuItem.type)"
                        id="route_name"
                        v-model="menuItem.route_name"
                        :placeholder="$t('Link Url')"
                    ></b-input>

                    <v-select
                        v-else
                        id="route_name"
                        disabled
                        v-model="menuItem.route_name"
                        :clearable="false"
                        :options="routeList(menuItem.type)"
                        :reduce="(text) => text.value"
                        class="rounded"
                        label="text"
                    />
                </b-form-group>

                <!-- Route_params -->
                <b-form-group
                    v-if="menuItem.type"
                    label="路由参数"
                    label-for="route_params"
                >
                    <b-form-input
                        id="route_params"
                        v-model="menuItem.route_params"
                        trim
                    />
                </b-form-group>

                <!-- Route_order -->
                <b-form-group label="排序" label-for="route_order">
                    <b-form-input
                        id="route_order"
                        v-model="menuItem.order"
                        trim
                    />
                </b-form-group>

                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" @click="onSubmit">
                        {{ $t("Submit") }}
                    </b-button>
                    <b-button
                        type="button"
                        variant="outline-secondary"
                        class="ml-1"
                        @click="hide"
                    >
                        {{ $t("Cancel") }}
                    </b-button>
                </div>
            </b-form>
        </template>
    </b-sidebar>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { reactive, toRefs, watch } from "@vue/composition-api";
import { alphaNum, email, required } from "@validations";
import formValidation from "@/@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import { showToast } from "@/libs/utils/showToast";
import { refreshMenu } from "@/libs/utils/refreshMenu";
import front_routes from "@/router/routes/front";
import admin_routes from "@/router/routes/admin";
import group_routes from "@/router/routes/panel-group";
import { t } from "@core/libs/i18n/utils";

export default {
    components: {
        vSelect,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    model: {
        prop: "isEditMenuSidebarActive",
        event: "update:is-edit-menu-sidebar-active",
    },
    props: ["isEditMenuSidebarActive"],
    setup(props, { emit }) {
        const project_selected = JSON.parse(
            localStorage.getItem("project-selected")
        ).project_id;
        const blankMenuItem = {
            menu_id: null,
            title: "新导航",
            project_id: project_selected,
            icon: "",
            type: 3,
            p_menu_id: 0,
            route_name: "",
            route_params: {
                src: "",
            },
            order: 0,
        };
        const event = reactive({
            menuItem: blankMenuItem,
            project_selected,
            required,
            alphaNum,
            email,
        });

        watch(
            () => store.state.menus.edit,
            (value) => {
                if (value.menu_id) {
                    event.menuItem = value;
                } else {
                    resetmenuItem();
                }
            }
        );
        const routeList = (type) => {
            let routeList = [];
            if (type === 1) {
                routeList = front_routes.filter(
                    (route) =>
                        !!route.meta && route.name !== "index-project-list"
                );
            } else if (type === 2) {
                routeList = admin_routes.filter((route) => !!route.meta);
            } else {
                routeList = group_routes.filter((route) => !!route.meta);
            }
            routeList.push({
                name: "error-coming-soon",
                meta: { pageTitle: "开发中" },
            });
            routeList.map((route) => {
                route.text = t(route.meta.pageTitle) + "( " + route.name + " )";
                route.value = route.name;
            });
            return routeList;
        };

        const resetmenuItem = () => {
            event.menuItem = JSON.parse(JSON.stringify(blankMenuItem));
        };

        const onSubmit = () => {
            updateMenu();
        };

        /**
         * 新增或更新菜单数据
         */
        const updateMenu = () => {
            if (event.menuItem.menu_id) {
                store
                    .dispatch("menus/editMenu", event.menuItem)
                    .then((response) => {
                        emit("update:isEditMenuSidebarActive", false);
                        refreshMenu();
                    })
                    .catch((error) => {
                        showToast(error);
                    });
            } else {
                //若新增panel-group则先添加空panel-group
                if (event.menuItem.type === 3) {
                    store
                        .dispatch("panel-group/addPanelGroup", {
                            group_name: event.menuItem.title,
                        })
                        .then((response) => {
                            const { group_uuid } = response.data;
                            event.menuItem.route_name = "panel-group";
                            event.menuItem.route_params = {
                                uid: group_uuid,
                            };
                            addMenu();
                        })
                        .catch((error) => {
                            showToast(error);
                        });
                } else {
                    addMenu();
                }
            }
        };

        const addMenu = () => {
            //若路由类型为目录，则清空路由名称
            if (event.menuItem.type === 0) {
                event.menuItem.route_name = "";
                event.menuItem.route_params = {};
            }
            //若路由类型为外链
            if (event.menuItem.type === 4) {
                event.menuItem.route_name = "index-project-iframe";
                event.menuItem.route_params = {
                    src: event.menuItem.route_params.src,
                };
            }
            store
                .dispatch("menus/addMenu", event.menuItem)
                .then((response) => {
                    emit("update:isEditMenuSidebarActive", false);
                    refreshMenu();
                })
                .catch((error) => {
                    showToast(error);
                });
        };
        const { refFormObserver, getValidationState, resetForm } =
            formValidation(resetmenuItem);

        return {
            ...toRefs(event),
            onSubmit,
            routeList,
            refFormObserver,
            getValidationState,
            resetForm,
        };
    },
};
</script>

<style lang="scss">
@import "src/@core/scss/vue/libs/vue-select.scss";

#edit-menu-sidebar {
    .vs__dropdown-menu {
        max-height: 200px !important;
    }
}
</style>
